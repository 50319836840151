import React, { useMemo, useRef, useState } from 'react';
import { Icon, IconProps } from '@global-ecom/nitro-uds/elements';

import { useListboxFocus } from 'hooks/a11y';
import { useLocation } from 'hooks/useLocation';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useTranslate } from 'hooks/useTranslations';
import { AnalyticsEvents, event as analyticsEvent } from 'utils/analytics';
import { SUPPORTED_COUNTRY } from 'utils/constants';
import { normalizeDiacriticalCharacters } from 'utils/string';
import { useTrackLanguageChangeMutation } from '__generated__/graphql';
import countriesJson from 'public/assets/locales.json';

import CloseButton from './CloseButton';

type Locale = {
  label: string;
  id: string;
  url: string;
};

export type Country = {
  id: string;
  url: string;
  isEcommerce: boolean;
  locales: Locale[];
  country: SUPPORTED_COUNTRY;
  search: string;
  translations: Record<string, string>;
};

const buildHref = (locale: Locale, country: Country): string => {
  return (
    locale.url ??
    `https://${country.url}/${
      country?.locales.length === 1 && !country.url.includes('eu.')
        ? ''
        : locale.id.split('_').reverse().join('/').toLowerCase() + '/'
    }`
  );
};

const LocaleLink = ({
  href,
  locale,
  content,
}: {
  href: string;
  locale: Locale;
  content: JSX.Element | string;
}) => {
  const [, trackLanguageChange] = useTrackLanguageChangeMutation();
  const language = locale.id.split('_')[0];
  return (
    <a
      key={locale.id}
      href={href}
      rel="noopener"
      hrefLang={locale.id.replace('_', '-').toLowerCase()}
      onClick={async () => {
        analyticsEvent(AnalyticsEvents.LANGUAGE_CHANGED, {
          newLanguage: language,
        });
        await trackLanguageChange({ language });
      }}
    >
      {content}
    </a>
  );
};

const LocationSelector: React.FC<{ isHidden?: boolean }> = ({
  isHidden = false,
}) => {
  const t = useTranslate();
  const [searchTerm, setSearchTerm] = useState('');
  const { language, countryCode } = useSiteConfig();
  const { location, setLocation } = useLocation();

  const filteredCountries = useMemo(() => {
    const countries: Country[] = countriesJson.map((country): Country => {
      const translation =
        country.translations[language] || country.translations['x-default'];
      if (translation.includes('/')) {
        const [firstPart, secondPart] =
          country.translations['x-default'].split('/');
        country.translations[
          'x-default'
        ] = `${secondPart.trim()}/${firstPart.trim()}`;
      } else {
        country.translations['x-default'] = translation;
      }
      return country as unknown as Country;
    });
    const sortedCountries = countries.sort((a: Country, b: Country) => {
      return a.translations['x-default'].localeCompare(
        b.translations['x-default']
      );
    });

    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = sortedCountries.filter(
      country =>
        country.id.toLowerCase().match(lowerCaseSearchTerm) ||
        Object.values(country.translations).some(value =>
          value.toLowerCase().match(lowerCaseSearchTerm)
        ) ||
        Object.values(country.locales).some(locale =>
          normalizeDiacriticalCharacters(locale.label)
            .toLowerCase()
            .match(normalizeDiacriticalCharacters(lowerCaseSearchTerm))
        )
    );

    const selectedCountryIndex = filtered.findIndex(
      country => countryCode === country.id.toLowerCase()
    );

    if (selectedCountryIndex > -1) {
      filtered.unshift(...filtered.splice(selectedCountryIndex, 1));
    }

    return filtered;
  }, [language, searchTerm, countryCode]);

  const inputRef = useRef(null);
  const menuRef = useRef(null);

  useListboxFocus(menuRef, {
    isActive: true,
    allowFocus: true,
    ownerRef: inputRef,
  });

  return (
    <div
      className={`space-y-3 xs:space-y-4 p-4 xs:p-6 ${
        isHidden ? 'hidden' : ''
      }`}
    >
      <div className="flex items-center justify-between">
        <div className="font-bold">{t('countrySelector')}</div>
        <CloseButton
          dataTestId="location-selector-close"
          onClick={() => setLocation({ ...location, selecting: false })}
        />
      </div>
      <div className="flex relative">
        <label htmlFor="location-selector-input" className="sr-only">
          {t('enterCountryOrLanguage')}
        </label>
        <input
          id="location-selector-input"
          data-test-id="location-selector-input"
          name="language"
          className="border pl-3 py-3.5 font-bold tracking-wide min-w-0 w-full bg-transparent"
          placeholder={t('enterCountryOrLanguage')}
          onChange={e => setSearchTerm(e.target.value)}
          role="combobox"
          aria-autocomplete="none"
          aria-expanded="true"
          aria-haspopup="true"
          aria-owns="location-results-listbox"
          ref={inputRef}
        />

        <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
          <Icon name="search" size="xl" color="muted" />
        </div>
      </div>
      <ul
        ref={menuRef}
        role="listbox"
        id="location-results-listbox"
        data-test-id="location-results-listbox"
        className="divide-y flex flex-col"
      >
        {!filteredCountries.length ? (
          <h3 data-test-id="location-results-no-results" className="mt-6 block">
            No search results
          </h3>
        ) : (
          filteredCountries.map(country => {
            const defaultLocale = country.locales?.[0];
            const defaultHref = buildHref(defaultLocale, country);
            const labelCountry = (
              <div className="flex items-center space-x-2 xs:space-x-4">
                <Icon
                  size="4xl"
                  name={
                    ('flag-' +
                      country.id.toLocaleLowerCase()) as IconProps['name']
                  }
                  className="border-1 rounded-full"
                />
                <span
                  data-test-id="location-country-name"
                  className="xs:text-lg"
                >
                  {country.translations[language] ||
                    country.translations['x-default']}
                </span>
              </div>
            );
            return (
              <li
                key={country.id}
                className="flex justify-between items-center space-x-10 py-3"
              >
                <LocaleLink
                  href={defaultHref}
                  locale={defaultLocale}
                  content={labelCountry}
                />
                <ul className="space-x-2" role="menu">
                  {country.locales.map(locale => {
                    const language = locale.id.split('_')[0];
                    const href = buildHref(locale, country);
                    return (
                      <li
                        lang={language}
                        key={locale.id}
                        className="text-xs underline text-puma-black-300 inline"
                        role="menuitem"
                      >
                        <LocaleLink
                          href={href}
                          locale={locale}
                          content={locale.label}
                        />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default LocationSelector;
